<template>
  <ion-page>
    <ion-header>
      <ion-toolbar :style="menuStyle">
        <ion-title> Verify Information </ion-title>
      </ion-toolbar>
    </ion-header>    
    <ion-content :style="contentStyle">
        <p class="verifyText">We need to verify your information. Let us know if you would like to receive a code via email or text.</p>
        <div class="ion-text-center" style="margin-botton: 26px"><button class="button">Email Code</button></div>  
  
        
        <ion-item>
          <ion-label style="numberField">Phone Number:</ion-label>
          <ion-input>

          </ion-input>
        </ion-item>
        <div class="ion-text-center" style="margin-top: 26px"><button class="button">Send via text</button>

        </div>  
                        <p style=" verifyText">Enter verification code below:</p>

      <ion-input class = "input1"></ion-input>
    </ion-content>    
  </ion-page>
</template>

<script >
import { useStore} from 'vuex';
import { IonPage, IonContent, IonItem, IonLabel, IonInput, IonHeader, IonToolbar, IonTitle } from '@ionic/vue';

export default {
  name: 'Start',
  components: {  IonPage, IonContent, IonItem, IonLabel, IonInput, IonHeader, IonToolbar, IonTitle },
  
  setup() {
      const store = useStore();
      const contentStyle = store.state.content
      const menuStyle = store.state.menu
      return {
        contentStyle, menuStyle
      }
  }
}
</script>
<style scoped>

.verifyText{
  color: #000; 
  padding: 10%; 
}
 .input1 { 
   width: 176px;
   height: 43px;
   border: black solid 1px;
   margin:  18px auto;
 }

 .numberField{
   width: 500px; 
 }
</style>
